import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import PartialPageImgHeader from "../components/PartialPageImgHeader";
import TitleXL from "../components/TitleXL";
import InhaltsText from "../components/InhaltsText";
import Wrapper from "../components/Wrapper";
import HorizontalWrapper from "../components/HorizontalWrapper";
import { Helmet } from "react-helmet";
import MapBox from "../components/MapBox";
import TwoThirdsGrid from "../components/TwoThirdsGrid";

interface KontaktQueryProps {
  cms: {
    kontakt: {
      title: string;
      mainImage: [{ url: string }];
      inhaltstext: string;
    };
    siteData: {
      googleMapsUrl: string;
    };
  };
}

const Kontakt = () => {
  const data: KontaktQueryProps = useStaticQuery(graphql`
    query {
      cms {
        kontakt: entry(type: "kontakt") {
          title
          ... on CMS_inhalt_kontakt_Entry {
            mainImage {
              url
            }
            inhaltstext
          }
        }
        siteData: entry(type: "sitedata") {
          ... on CMS_andereInhalte_sitedata_Entry {
            googleMapsUrl
          }
        }
      }
    }
  `);
  const entry = data.cms.kontakt;

  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <PartialPageImgHeader image={entry.mainImage[0].url} />
      <Wrapper>
        <TwoThirdsGrid>
          <div>
            <TitleXL>{entry.title}</TitleXL>
            <InhaltsText html={entry.inhaltstext} />
          </div>
          <div>
            <MapBox />
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href={data.cms.siteData.googleMapsUrl}
            >
              in Google Maps öffnen
            </a>
          </div>
        </TwoThirdsGrid>
      </Wrapper>
    </DefaultLayout>
  );
};

export default Kontakt;
