import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import mapboxgl from "mapbox-gl";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZ29lYXN0IiwiYSI6ImNqcWY5OXJmajIxMG00MnQ3ZXpsMGFwcDIifQ.5hMtuM7NCx7STL38ftqtMg";

//Documentation: https://docs.mapbox.com/mapbox-gl-js/api/#quickstart

const MapBox: React.FC = () => {
  const mapContainerRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [map, setMap] = useState(null); //not being read

  const mapContainerStyle = {
    width: "100%",
    height: "30vh",
    minHeight: 400,
    marginTop: 24,
  };

  useEffect(() => {
    if (mapLoaded == false) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        accessToken: MAPBOX_TOKEN,
        style: "mapbox://styles/goeast/ckljqh2ns0nd317uke1bgmi68",
        center: [9.38168234, 47.42],
        zoom: 13.5,
      });
      setMap(map);

      const marker = new mapboxgl.Marker({ color: "#3A5069" })
        .setLngLat([9.38168234, 47.41685774])
        .addTo(map);
      setMapLoaded(true);
    } else {
      return;
    }
  });

  return (
    <>
      <Helmet>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v1.13.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>
      <div ref={mapContainerRef} style={mapContainerStyle}></div>
    </>
  );
};

export default MapBox;
