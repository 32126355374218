import React from "react";
import styled from "styled-components";
import { spacers, breakpoints } from "../styles/variables";

interface TwoThirdsGridProps {
  children: React.ReactNode;
}

const GridDiv = styled.div`
  @media screen AND (min-width: ${breakpoints.m}px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: ${spacers.m}px;
  }

  @media screen AND (min-width: ${breakpoints.l}px) {
    grid-gap: ${spacers.l}px;
  }
`;

const TwoThirdsGrid: React.FC<TwoThirdsGridProps> = ({ children }) => (
  <GridDiv>{children}</GridDiv>
);

export default TwoThirdsGrid;
